/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from 'react';

import {
    Box,
    IconButton, List,
    ListItem, ListItemAvatar, ListItemText, Modal
} from '@mui/material';

import {
    CalendarMonth as CalendarIcon
} from '@mui/icons-material';

import api from '../../service';
import { Event } from './ScheduleDesktop';
import {
    CalendarBody, CalendarContainer, CalendarDayMobile, CalendarHead, Container, ImageMobile, ListEvents, ListEventTitle, Root, Title
} from './styles';

const ScheduleMobile: React.FC = () => {
    const [modalShow, setModalShow] = useState<boolean>(false)
    const [imagemSel, setImagemSel] = useState("");
    const [events, setEvents] = useState<Event>({} as Event)

    const getAgenda = useCallback(async () => {
        const res = await api.get('site/schedule');
        setEvents({
            now: res.data.now,
            days: res.data.days
        })
    }, [])

    const showImagem = useCallback((imagem: string) => {
        setImagemSel(imagem)
        setModalShow(true)
    }, [])

    const handleClose = () => setModalShow(false);

    useEffect(() => {
        getAgenda();
    }, [getAgenda])

    return (
        <Root>
            <Container>
                <Title>{events && events.now && events.now.toLocaleUpperCase()}</Title>
                <CalendarContainer>
                    <CalendarHead>
                        <CalendarDayMobile head>
                            <span className="content">DOM</span>
                        </CalendarDayMobile>
                        <CalendarDayMobile head>
                            <span className="content">SEG</span>
                        </CalendarDayMobile>
                        <CalendarDayMobile head>
                            <span className="content">TER</span>
                        </CalendarDayMobile>
                        <CalendarDayMobile head>
                            <span className="content">QUA</span>
                        </CalendarDayMobile>
                        <CalendarDayMobile head>
                            <span className="content">QUI</span>
                        </CalendarDayMobile>
                        <CalendarDayMobile head>
                            <span className="content">SEX</span>
                        </CalendarDayMobile>
                        <CalendarDayMobile head>
                            <span className="content">SAB</span>
                        </CalendarDayMobile>
                    </CalendarHead>
                    <CalendarBody>
                        {events && events.days && events.days.map(event => {

                            return (
                                <>
                                    <CalendarDayMobile
                                        color={event.color}
                                        today={event.today}
                                        disabled={event.disabled}
                                        event={!!event.event}
                                        onClick={() => event.image != null && showImagem(event.image)}
                                    >
                                        <span className="content">
                                            {event.day}
                                            {event.event && <span className="event" >{event.event}</span>}
                                        </span>

                                    </CalendarDayMobile>
                                </>
                            )
                        })}
                    </CalendarBody>
                </CalendarContainer>

                <br />
                <ListEvents>
                    <ListEventTitle>Lista de Eventos</ListEventTitle>

                    <List sx={{ width: '100%', maxWidth: 1020, bgcolor: 'background.paper' }}>
                        {events && events.days && events.days.map(event => {
                            if (event.event == null) return null;

                            return (
                                <ListItem
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="show"
                                            onClick={() => showImagem(event.image)}
                                        >
                                            <CalendarIcon color='secondary' />
                                        </IconButton>
                                    }
                                >
                                    {event.image && (
                                        <ListItemAvatar>
                                            <img src={event.image} style={{ borderRadius: 2, marginRight: 16 }} width={140} />
                                        </ListItemAvatar>
                                    )}
                                    <ListItemText primary={(
                                        <h3 style={{ paddingBottom: 'none', }} >{event.text}</h3>
                                    )} secondary={(
                                        <>
                                            <strong style={{ fontSize: '16px', fontWeight: 500 }}>{event.date && event.date.toLocaleUpperCase()}</strong>
                                            <br />
                                            <span style={{ fontSize: '14px', fontWeight: 600 }}>{event.hour}</span>
                                            <br />
                                            <p style={{ fontSize: '10px', fontWeight: 700 }}>{event.pass}</p>
                                        </>
                                    )} />
                                </ListItem>
                            )
                        })}
                    </List>
                </ListEvents>
            </Container>
            <div>
                <Modal
                    open={modalShow}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box>
                        <div
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
                            onClick={() => setModalShow(false)}
                        >
                            <ImageMobile src={imagemSel} width={300} />
                        </div>
                    </Box>
                </Modal>
            </div>
        </Root>
    );
}

export default ScheduleMobile;

import "react-image-gallery/styles/css/image-gallery.css";
import React from 'react';
import { ThemeProvider } from 'styled-components'
import theme from './styles/theme'

import {
  CssBaseline,
  Stack,
} from '@mui/material';

import Master from './layout';
import UIProvider from './context/ui';
import Routes from "./routes";

const App: React.FC = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UIProvider>
          <Master>
            <Stack>
              <Routes />
            </Stack>
          </Master>
        </UIProvider>
      </ThemeProvider>
    </>
  )
}

export default App;
import React from 'react';

// import { Container } from './styles';

const Location: React.FC = () => {
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.0592976504995!2d-46.870100684391446!3d-23.494373565008328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf03b5f20d4381%3A0xdca5ff2f55fe7f01!2sR.%20Sol%2C%20280%20-%20Jardim%20Tupanci%2C%20Barueri%20-%20SP%2C%2006414-070!5e0!3m2!1spt-BR!2sbr!4v1676128746624!5m2!1spt-BR!2sbr"
        height="450"
        style={{ border: 0, width: '100%' }} />
    </>
  );
}

export default Location;
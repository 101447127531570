/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from 'react';


import {
    Box,
    IconButton, List,
    ListItem, ListItemAvatar, ListItemText, Modal
} from '@mui/material';

import {
    CalendarMonth as CalendarIcon
} from '@mui/icons-material';


import api from '../../service';
import {
    CalendarBody, CalendarContainer, CalendarDay, CalendarHead, Container, Image, ListEventTitle, Root, Title
} from './styles';

export interface Schedule {
    day: string;
    disabled: boolean;
    color: string;
    event: string;
    text: string;
    pass: string;
    date: string;
    hour: string;
    image: string;
    today?: boolean;
}

export interface Event {
    now: string;
    days: Schedule[];
}

const ScheduleDesktop: React.FC = () => {
    const [modalShow, setModalShow] = useState<boolean>(false)
    const [imagemSel, setImagemSel] = useState("");
    const [events, setEvents] = useState<Event>({} as Event)

    const getAgenda = useCallback(async () => {
        const res = await api.get('site/schedule');
        setEvents({
            now: res.data.now,
            days: res.data.days
        })
    }, [])

    const showImagem = useCallback((imagem: string) => {
        setImagemSel(imagem)
        setModalShow(true)
    }, [])

    const handleClose = () => setModalShow(false);

    useEffect(() => {
        getAgenda();
    }, [getAgenda])

    return (
        <Root>
            <Container>
                <Title>{events && events.now && events.now.toLocaleUpperCase()}</Title>
                <CalendarContainer>
                    <CalendarHead>
                        <CalendarDay head>
                            <span className="content">DOM</span>
                        </CalendarDay>
                        <CalendarDay head>
                            <span className="content">SEG</span>
                        </CalendarDay>
                        <CalendarDay head>
                            <span className="content">TER</span>
                        </CalendarDay>
                        <CalendarDay head>
                            <span className="content">QUA</span>
                        </CalendarDay>
                        <CalendarDay head>
                            <span className="content">QUI</span>
                        </CalendarDay>
                        <CalendarDay head>
                            <span className="content">SEX</span>
                        </CalendarDay>
                        <CalendarDay head>
                            <span className="content">SAB</span>
                        </CalendarDay>
                    </CalendarHead>
                    <CalendarBody>
                        {events && events.days && events.days.map(event => {

                            return (
                                <>
                                    <CalendarDay
                                        color={event.color}
                                        today={event.today}
                                        disabled={event.disabled}
                                        event={!!event.event}
                                        onClick={() => event.image != null && showImagem(event.image)}
                                    >
                                        <span className="content">
                                            {event.day}
                                            {event.event && <span className="event" >{event.event}</span>}
                                        </span>

                                    </CalendarDay>
                                </>
                            )
                        })}
                    </CalendarBody>
                </CalendarContainer>
                <br />
                <ListEventTitle>Lista de Eventos</ListEventTitle>
                <List sx={{ width: '100%', maxWidth: 1020, bgcolor: 'background.paper' }}>
                    {events && events.days && events.days.map(event => {
                        if (event.event == null) return null;

                        return (
                            <ListItem
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="show"
                                        onClick={() => showImagem(event.image)}
                                    >
                                        <CalendarIcon color='secondary' />
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar>
                                    <img src={event.image} style={{ borderRadius: 2, marginRight: 16 }} width={140} />
                                </ListItemAvatar>
                                <ListItemText primary={(
                                    <h3 style={{ paddingBottom: 'none', }} >{event.text}</h3>
                                )} secondary={(
                                    <>
                                        <strong style={{ fontSize: '20px', fontWeight: 500 }}>{event.date && event.date.toLocaleUpperCase()}</strong>
                                        <br />
                                        <span style={{ fontSize: '18px', fontWeight: 600 }}>{event.hour}</span>
                                        <br />
                                        <p style={{ fontSize: '12px', fontWeight: 700 }}>{event.pass}</p>
                                    </>
                                )} />
                            </ListItem>
                        )
                    })}
                </List>
            </Container>
            <div>
                <Modal
                    open={modalShow}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box>
                        <div
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
                            onClick={() => setModalShow(false)}
                        >
                            <Image src={imagemSel} alt="Cursos baralho" width={300} />
                        </div>
                    </Box>
                </Modal>
            </div>
        </Root>

    );
}

export default ScheduleDesktop;

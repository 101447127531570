import styled from 'styled-components';

import { Colors } from '../../layout/Themes/Theme01/theme';

export const Root = styled.div`
    display: flex;
    justify-content:  center;
    align-items: center;

    flex-direction: column;
    user-select: none;
`;

export const Container = styled.div`
  display: flex;
  justify-content:  center;
  align-items: center;

  flex-direction: column;

  width: 90%;
`;

export const Title = styled.span`
    font-size: 28px;
    font-weight: 900;
    margin-bottom: 2rem;
`;

export const ListEvents = styled.div`
    display: flex;  
    justify-content:  center;
    align-items: center;
    flex-direction: column;
`;

export const ListEventTitle = styled.span`
    font-size: 20px;
    font-weight: 500;
`;

export const CalendarContainer = styled.div`
    padding: 5px;
    width: 100%;
    max-width: 1028px;

    border: 1px solid ${Colors.primary};
    border-radius: 8px;
`;

export const CalendarHead = styled.div`
    display: flex;  
    justify-content:  center;
    align-items: center;

    flex-direction: row;
    
    border-bottom: none;
    width: 100%;
`;

export const CalendarBody = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    
`;


export interface CalendarDayProps {
    color?: string;
    head?: boolean;
    disabled?: boolean;
    today?: boolean;
    event?: boolean;
}

export const CalendarDay = styled.span<CalendarDayProps>`
    position: relative;
    flex-basis: calc(14.286% - 2px);
    margin: 1px;
    
    cursor:  ${props => props.disabled ? 'no-drop' : 'pointer'}; 
    font-weight: 300;

    width: 220px;
    height: 140px;
    user-select: none;

    .content {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid ${Colors.border};
        font-size: 22px;
        font-weight: ${props => props.today ? 'bold' : ''};
        font-weight: ${props => props.head ? 'bold' : ''};
        font-weight: ${props => props.event ? 'bold' : ''};

        color: ${props => props.head ? Colors.primary : '#000'};
        color: ${props => props.color ? '#FFF' : '#000'};
        color: ${props => props.today ? '#FFF' : '#000'};        

        background-color: ${props => props.color ? props.color : props.color};
        background-color: ${props => props.disabled ? '#efecec' : '#FFF'};
        background-color: ${props => props.today ? Colors.primary : props.color};    
        background-color: ${props => props.head ? '#68a1db' : props.color};

        .event {
            color: ${Colors.primary};
            color: ${props => props.today ? '#FFF' : '#000'};
            font-weight: bold;
            font-size: 24px;
        }
    }
`;

export const CalendarRow = styled.div`
    display: flex;
    justify-content:  center;
    align-items: center;

    flex-direction: row;

    border: 1px solid ${Colors.border};
    border-right: none;
    border-left: none;

    width: 100%;
`;

export interface CalendarItemProps {
    color?: string;
    head?: boolean;
    disabled?: boolean;
}

export const CalendarItem = styled.div<CalendarItemProps>`
    display: flex;
    justify-content:  center;
    align-items: center;

    flex-direction: column;

    width: 145px;
    height: 130px;

    cursor:  ${props => props.disabled ? 'no-drop' : 'pointer'}; 
    user-select: none;

    border: 1px solid ${Colors.border};

    color: ${props => props.color ? '#FFF' : '#000'};
    color: ${props => props.head ? '#000' : ''};

    background-color: ${props => props.disabled ? '#efecec' : props.color ? props.color : '#FFF'};

    border-top: none;
    border-bottom: none;

    &:last-child {
        border-right: none;
    }

    &:first-child {
        border-left: none;
    }
`;

export const CalendarItemMobile = styled.div<CalendarItemProps>`
   display: flex;
    justify-content:  center;
    align-items: center;

    flex-direction: column;

    width: 65px;
    height: 50px;

    cursor:  ${props => props.disabled ? 'no-drop' : 'pointer'}; 
    user-select: none;

    border: 1px solid ${Colors.border};

    color: ${props => props.color ? '#FFF' : '#000'};
    color: ${props => props.head ? '#000' : ''};

    background-color: ${props => props.disabled ? '#efecec' : props.color ? props.color : '#FFF'};

    border-top: none;
    border-bottom: none;

    &:last-child {
        border-right: none;
    }

    &:first-child {
        border-left: none;
    }

`;

export const CalendarDayMobile = styled.span<CalendarDayProps>`
    position: relative;
    flex-basis: calc(14.286% - 2px);
    margin: 1px;
    
    cursor:  ${props => props.event ? 'pointer' : 'no-drop'}; 
    font-weight: 300;

    width: 40px;
    height: 50px;
    

    user-select: none;
    text-overflow: ellipsis;
    .content {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid ${Colors.border};
        font-size: 15px;
        font-weight: ${props => props.today ? 'bold' : ''};
        font-weight: ${props => props.head ? 'bold' : ''};
        font-weight: ${props => props.event ? 'bold' : ''};
        text-overflow: ellipsis;
        color: ${props => props.head ? Colors.primary : '#000'};
        color: ${props => props.color ? '#FFF' : '#000'};
        color: ${props => props.today ? '#FFF' : '#000'};        

        background-color: ${props => props.color ? props.color : props.color};
        background-color: ${props => props.disabled ? '#efecec' : '#FFF'};
        background-color: ${props => props.today ? Colors.primary : props.color};    
        background-color: ${props => props.head ? '#68a1db' : props.color};


        .event {
            color: ${Colors.primary};
            color: ${props => props.today ? '#FFF' : '#000'};
            font-weight: bold;
            font-size: 9px;
            max-width: 40px;
            text-overflow: ellipsis;
        }

    }
`;


export const Image = styled.img`
    width: 50%;
`;


export const ImageMobile = styled.img`
    width: 90%;
`;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from 'react';

import {
    CalendarMonth as CalendarIcon, Facebook as FacebookIcon, Home as HomeIcon,
    Info as InfoIcon, Instagram as InstagramIcon, WebAsset as WebAssetIcon, YouTube as YouTubeIcon
} from "@mui/icons-material";

import { useNavigate } from 'react-router-dom';
import {
    CompanyAddress,
    CompanyAddressText, CompanyCopyRight,
    CompanyCopyRightText, CompanyMessage,
    CompanyMessageText, CompanyName,
    CompanyNameText, CompanySocialNetwork,
    CompanySocialNetworkTitle, Container,
    ContainerLeft, ContainerRight, ContainerRightLinks, DevelopCompany, LinksFooter, LinksFooterItem, LinksFooterItemLink
} from './styles';

const AppFooterDesktop: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    return (
        <>
            <Container>
                <ContainerLeft>

                    <CompanyName>
                        <CompanyNameText>Tenda de Umbanda Ogum 7 Espadas</CompanyNameText>
                    </CompanyName>

                    <CompanyAddress>
                        <CompanyAddressText>Rua Sol, 280 - Jardim Tupanci - Barueri - SP - CEP: 06414-070</CompanyAddressText>
                    </CompanyAddress>

                    <CompanySocialNetwork>
                        <a href="http://tendaogum7espadas.com.br" target="_blank" rel="noopener noreferrer" style={{ color: '#FFF', marginRight: 5 }} >
                            <WebAssetIcon />
                        </a>
                        <a href="https://www.facebook.com/tendaogum7espadas" target="_blank" rel="noopener noreferrer" style={{ color: '#FFF', marginRight: 5 }}>
                            <FacebookIcon />
                        </a>
                        <a href="https://www.instagram.com/tendaogum7espadas" target="_blank" rel="noopener noreferrer" style={{ color: '#FFF', marginRight: 5 }}>
                            <InstagramIcon />
                        </a>
                        <a href="https://www.youtube.com/@tendaogum7espadas853" target="_blank" rel="noopener noreferrer" style={{ color: '#FFF', marginRight: 5 }}>
                            <YouTubeIcon />
                        </a>
                        <CompanySocialNetworkTitle>TENDAOGUM7ESPADAS</CompanySocialNetworkTitle>
                    </CompanySocialNetwork>

                    <CompanyMessage>
                        <CompanyMessageText>Umbanda – Fé, amor, caridade,</CompanyMessageText>
                        <CompanyMessageText>trabalho, estudo e esforço na prática do bem!</CompanyMessageText>
                        <CompanyMessageText>"Com quem sabe mais, aprenderemos. A quem sabe menos, ensinaremos"</CompanyMessageText>
                        <CompanyMessageText>Caboclo 7 Encruzilhadas</CompanyMessageText>
                    </CompanyMessage>

                    <CompanyCopyRight>
                        <CompanyCopyRightText>Copyright 2023 - Tenda de Umbanda Ogum 7 Espadas</CompanyCopyRightText>
                    </CompanyCopyRight>

                </ContainerLeft>

                {!matches && (
                    <ContainerRight>
                        <ContainerRightLinks>
                            <LinksFooter>
                                <LinksFooterItem>
                                    <LinksFooterItemLink onClick={() => navigate('/')}>
                                        <HomeIcon />
                                        <span>HOME</span>
                                    </LinksFooterItemLink>
                                </LinksFooterItem>

                                <LinksFooterItem>
                                    <LinksFooterItemLink onClick={() => navigate('/quem-somos')}>
                                        <InfoIcon />
                                        <span>QUEM SOMOS</span>
                                    </LinksFooterItemLink>
                                </LinksFooterItem>

                                {/* <LinksFooterItem>
                                    <LinksFooterItemLink>
                                        <WhatsAppIcon />
                                        <span>CONTATO</span>
                                    </LinksFooterItemLink>
                                </LinksFooterItem> */}

                                <LinksFooterItem>
                                    <LinksFooterItemLink onClick={() => navigate('/agenda')}>
                                        <CalendarIcon />
                                        <span>AGENDA</span>
                                    </LinksFooterItemLink>
                                </LinksFooterItem>
                                {/* 
                                <LinksFooterItem>
                                    <LinksFooterItemLink>
                                        <BookIcon />
                                        <span>ESTUDOS</span>
                                    </LinksFooterItemLink>
                                </LinksFooterItem>

                                <LinksFooterItem>
                                    <LinksFooterItemLink>
                                        <SchoolIcon />
                                        <span>CURSOS</span>
                                    </LinksFooterItemLink>
                                </LinksFooterItem>

                                <LinksFooterItem>
                                    <LinksFooterItemLink>
                                        <PhotoAlbumIcon />
                                        <span>FOTOS</span>
                                    </LinksFooterItemLink>
                                </LinksFooterItem> */}




                            </LinksFooter>
                        </ContainerRightLinks>


                        <DevelopCompany>
                            <a href="https://provehito.com.br" target="_blank" rel="noopener noreferrer">
                                <span>Desenvolvido por Provehito Sistemas</span>
                            </a>
                        </DevelopCompany>

                    </ContainerRight>
                )}

            </Container>

        </>
    );
}

export default AppFooterDesktop;
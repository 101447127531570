import React from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import PrivateRoute from './Route';

import About from '../screen/About';
import AdminAgenda from '../screen/Admin/Agenda';
import AdminCursos from '../screen/Admin/Cursos';
import AdminEstudos from '../screen/Admin/Estudos';
import Contact from '../screen/Contact';
import GalleryPhoto from "../screen/GalleryPhoto";
import GalleryPhotoShow from "../screen/GalleryPhoto/GalleryPhotoShow";
import Home from '../screen/Home';
import Location from '../screen/Location';
import Login from '../screen/Login';
import Schedule from '../screen/Schedule';
import Sourses from '../screen/Sourses';
import SoursesDesktopShow from "../screen/Sourses/SoursesDesktopShow";
import Studies from '../screen/Studies';
import StudiesShow from '../screen/Studies/StudiesShow';

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route>
                <>
                    <Route path="/" element={<Home />} />
                    <Route path="/quem-somos" element={<About />} />
                    <Route path="/localizacao" element={<Location />} />
                    <Route path="/contato" element={<Contact />} />
                    <Route path="/agenda" element={<Schedule />} />
                    <Route path="/estudos" element={<Studies />} />
                    <Route path="/estudo/:id" element={<StudiesShow />} />
                    <Route path="/cursos" element={<Sourses />} />
                    <Route path="/curso/:id" element={<SoursesDesktopShow />} />
                    <Route path="/fotos" element={<GalleryPhoto />} />
                    <Route path="/foto/:id" element={<GalleryPhotoShow />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </>
            </Route>

            <Route path="/login" element={<PrivateRoute isLogin><Login /></PrivateRoute>} />
            <Route path="/admin/estudos" element={<PrivateRoute isAdmin><AdminEstudos /></PrivateRoute>} />
            <Route path="/admin/cursos" element={<PrivateRoute isAdmin><AdminCursos /></PrivateRoute>} />
            <Route path="/admin/agenda" element={<PrivateRoute isAdmin><AdminAgenda /></PrivateRoute>} />

        </Switch>
    );
}

export default Routes;